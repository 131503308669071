@import "../../include";

.container {
  @extend %content-container;
}

.titleAndDescription {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 50px;
    font-weight: 700;
    line-height: 68px;
    color: #282828;
    text-align: center;
    text-transform: capitalize;

    i {
      color: #4690FF;
    }

    @media #{$phone} {
      margin-left: -2px; // Designer hello
      font-size: 24px;
      line-height: 32px;
    }
  }

  h2 + p {
    margin-top: 26px;

    @media #{$phone} {
      margin-top: 24px;
    }
  }

  p {
    font-size: 25px;
    font-weight: 500;
    line-height: 32px;
    color: #282828;
    text-align: center;
    letter-spacing: -0.45px;

    @media #{$phone} {
      font-size: 18px;
      line-height: 25px;
      letter-spacing: unset;
    }
  }
}

.titleAndDescription + .logosListContainer {
  margin-top: 70px;

  @media #{$phone} {
    margin-top: 40px;
  }
}

.logosListContainer {
  position: relative;
  z-index: 0;

  @media #{$phone} {
    max-width: 267px;
    margin-right: auto;
    margin-left: auto;
  }
}

.logosList {
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 222px;

  background: #4690FF;
  border-radius: 15px;

  @media #{$phone} {
    flex-direction: column;
    padding: 38px 32px;
  }
}

.designElement {
  position: absolute;
  z-index: -1;
  width: 165px;
  height: 165px;

  &_left {
    @media #{$tablet-and-desktop} {
      top: 121px;
      left: -112px;
    }

    @media #{$phone} {
      bottom: -22px;
      left: -34px;
    }
  }

  &_right {
    @media #{$tablet-and-desktop} {
      top: -10px;
      right: -70px;
    }

    @media #{$phone} {
      top: -20px;
      right: -34px;
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }

  @media #{$phone} {
    width: 66px;
    height: 66px;
  }
}


.logo {
  &_ibm {
    width: 147px;
    height: 57px;

    @media #{$phone} {
      width: 92px;
      height: 37px;
    }
  }

  &_cox {
    width: 216px;
    height: 67px;

    @media #{$phone} {
      width: 192px;
      height: 58px;
    }
  }

  &_cibc {
    width: 222px;
    height: 56px;

    @media #{$phone} {
      width: 139px;
      height: 35px;
    }
  }

  &_bell {
    width: 115px;
    height: 67px;

    @media #{$phone} {
      width: 71px;
      height: 41px;
    }
  }

  & img, & svg {
    width: 100%;
    height: auto;
  }

  & + & {
    @media #{$tablet-and-desktop} {
      margin-left: 100px;
    }

    @media #{$phone} {
      margin-top: 92px;
    }
  }
}
