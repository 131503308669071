@import "../../include";

.container {
  @extend %content-container;
}

.titleAndDescription {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin-left: -10px; // Designer hello

    font-size: 50px;
    font-weight: 700;
    line-height: 68px;
    color: #282828;
    text-align: center;
    text-transform: capitalize;

    i {
      color: #4690FF;
    }

    @media #{$phone} {
      margin-left: -2px; // Designer hello
      font-size: 24px;
      line-height: 32px;
    }
  }

  h2 + p {
    margin-top: 26px;
  }

  p {
    font-size: 25px;
    font-weight: 500;
    line-height: 32px;
    color: #282828;
    text-align: center;
    letter-spacing: -0.45px;

    @media #{$phone} {
      font-size: 18px;
      line-height: 25px;
      letter-spacing: unset;
    }
  }
}

.titleAndDescription + .servicesContainer {
  margin-top: 66px;

  @media #{$phone} {
    margin-top: 42px;
  }
}

.servicesContainer {
  display: flex;

  @media #{$phone} {
    flex-direction: column;
  }
}

.servicesImage {
  overflow: hidden;
  width: 100%;
  border-radius: 40px;

  @media #{$tablet-and-desktop} {
    flex-shrink: 0;
    width: 100%;
    max-width: 735px;
  }

  @media #{$phone} {
    aspect-ratio: 335/224;
  }
}

.servicesImage + .servicesGridContainer {
  @media #{$tablet-and-desktop} {
    margin-left: 140px;
  }

  @media #{$phone} {
    margin-top: 40px;
  }
}

.servicesGrid {
  @media #{$tablet-and-desktop} {
    display: flex;
    flex-wrap: wrap;
    margin: -28px -27px;
  }
}

.serviceItem {
  @media #{$tablet-and-desktop} {
    width: 50%;
    padding: 28px 27px;
  }

  @media #{$phone} {
    display: flex;
    flex-direction: column;
    align-items: center;

    & + & {
      margin-top: 50px;
    }
  }
}


.serviceItemIcon {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 144px;
  height: 144px;

  background: #4690FF;
  border-radius: 24px;

  & > * {
    width: 70px;
    height: 70px;
  }

  @media #{$phone} {
    width: 119px;
    height: 119px;
  }

  /* & > * {
    width: auto;
    height: 100%;
  } */
}

.serviceItemIcon_permanentHiring svg {
  transform: translate(1px, 1px) scale(0.9);
}

.serviceItemIcon + .serviceItemTitle {
  @media #{$tablet-and-desktop} {
    margin-top: 40px;
  }

  @media #{$phone} {
    margin-top: 20px;
  }
}

.serviceItemTitle {
  font-size: 30px;
  font-weight: 700;
  line-height: 41px;
  text-transform: capitalize;

  @media #{$phone} {
    max-width: 264px;

    font-size: 20px;
    font-weight: 900;
    line-height: 27px;
    text-align: center;
  }
}

.desktopLineBreak {
  @media #{$phone} {
    display: none;
  }
}

.mobileLineBreak {
  @media #{$tablet-and-desktop} {
    display: none;
  }
}
