@import "../../include";

.container {
  @extend %content-container;

  display: flex;

  @media #{$phone} {
    flex-direction: column;
  }
}

.titleAndDescription {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  max-width: 420px;


  h2 {
    font-size: 50px;
    font-weight: 700;
    line-height: 53px;
    color: #282828;

    i {
      color: #4690FF;
    }

    @media #{$tablet-and-desktop} {
      letter-spacing: 1.1px;
    }

    @media #{$phone} {
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      text-transform: capitalize;
    }
  }

  h2 + p {
    margin-top: 26px;

    @media #{$phone} {
      margin-top: 24px;
    }
  }

  p {
    font-size: 25px;
    font-weight: 500;
    line-height: 32px;
    color: #282828;
    letter-spacing: -0.45px;

    @media #{$phone} {
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      letter-spacing: unset;
    }
  }

  p + .swirlingArrowContainer {
    margin-top: 20px;
  }

  @media #{$phone} {
    align-items: center;
  }
}

.swirlingArrowContainer {
  @media #{$phone} { // hello designer
    padding-top: 4px;
    padding-left: 16px;
  }
}

.swirlingArrow {
  width: 250px;
  height: 64px;

  @media #{$phone} {
    display: none;
  }
}

.swirlingArrowMobile {
  width: 107px;
  height: 87px;

  @media #{$tablet-and-desktop} {
    display: none;
  }
}

.titleAndDescription + .reasonsForChoosing {
  @media #{$tablet-and-desktop} {
    margin-left: 80px;
  }

  @media #{$phone} {
    margin-top: 17px;
  }
}

.reasonsForChoosing {
  display: flex;
  flex-grow: 1;

  // Hack for prevent flex child overgrow
  // https://stackoverflow.com/questions/41674979/flex-child-is-growing-out-of-parent
  min-width: 0;

  @media #{$phone} {
    justify-content: center;
  }
}

.swiperContainer {
  @media #{$phone} {
    padding-bottom: 40px;

    :global .swiper-pagination-bullets.swiper-pagination-horizontal {
      display: flex;
      align-items: center;
      justify-content: center;

      :global .swiper-pagination-bullet {
        $d: 11px;

        width: $d;
        height: $d;
        margin: 0 9px;

        opacity: 1;
        background-color: #4690FF;
      }

      :global .swiper-pagination-bullet.swiper-pagination-bullet-active {
        box-shadow: 0 0 0 5px rgb(70 144 255 / 30%), 0 0 0 1px rgb(70 144 255 / 100%);
      }
    }
  }
}

.reasonContainer {
  display: flex;
  justify-content: center;

  @media #{$phone} {
    display: flex;
    justify-content: center;
  }
}

.reason {
  @media #{$tablet-and-desktop} {
    flex-shrink: 0;
    width: 100%;
    max-width: 255px;
  }

  @media #{$phone} {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    max-width: 220px;
  }
}

.reasonTitle {
  font-weight: 700;
  color: #4690FF;

  @media #{$tablet-and-desktop} {
    margin-top: -4px; // hello designer
    font-size: 80px;
    line-height: 80px;
  }

  @media #{$phone} {
    padding-top: 4px;
    font-size: 40px;
    line-height: 46px;
    text-align: center;
  }
}

.reasonTitle + .reasonSubtitle {
  @media #{$tablet-and-desktop} {
    margin-top: 20px;
  }
}

.reasonSubtitle {
  font-size: 30px;
  font-weight: 700;
  line-height: 25px;
  color: #4690FF;

  @media #{$phone} {
    font-size: 20px;
    line-height: 22px;
    text-align: center;
  }
}

.reasonSubtitle + .reasonDescription {
  margin-top: 30px;

  @media #{$phone} {
    margin-top: 18px;
  }
}

.reasonDescription {
  font-size: 22px;
  font-weight: 500;
  line-height: 27px;
  color: #282828;

  @media #{$phone} {
    text-align: center;
  }
}
