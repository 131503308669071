@import "../include";

.container {
  width: 100%;
}

.banner {
  padding-top: 6px;
}

.banner + .partOurServices {
  margin-top: 200px;

  @media #{$phone} {
    margin-top: 122px;
  }
}

.partOurServices + .partTrustedBy {
  margin-top: 196px;

  @media #{$phone} {
    margin-top: 103px;
  }
}

.partTrustedBy + .partWhyWeRightChoice {
  margin-top: 200px;

  @media #{$phone} {
    margin-top: 100px;
  }
}

.partWhyWeRightChoice + .partIndustryInsights {
  margin-top: 216px;

  @media #{$phone} {
    margin-top: 97px;
  }
}

.partIndustryInsights + .discussHiringNeeds {
  margin-top: 200px;

  @media #{$phone} {
    margin-top: 96px;
  }
}


.blueDotsLeft {
  position: absolute;
  top: 390px;
  left: 0;

  @media (width <= 1700px) {
    display: none;
  }
}

.bubbleRightTop {
  position: absolute;
  top: -42px;
  right: -323px;

  width: 676px;
  height: 871px;

  background: radial-gradient(48.35% 48.16% at 46.6% 51.84%, rgb(255 123 202 / 40%) 0%, rgb(255 123 202 / 0%) 82.35%);

  @media #{$phone} {
    display: none;
  }
}


.bubbleRightMiddle {
  position: absolute;
  top: 1181px;
  right: -535px;

  width: 915px;
  height: 1024px;

  opacity: 0.65;
  background: radial-gradient(47.24% 47.24% at 49.9% 52.76%, rgb(242 47 176 / 40%) 0%, rgb(245 138 37 / 0%) 100%);

  @media #{$phone} {
    display: none;
  }
}

.bubbleRightBottom {
  position: absolute;
  top: 3290px;
  right: -467px;
  left: 1552px;

  width: 835px;
  height: 768px;

  background: radial-gradient(50% 50% at 50% 50%, rgb(66 221 255 / 40%) 0%, rgb(17 112 255 / 0%) 100%);

  @media #{$phone} {
    display: none;
  }
}

.bubbleLeft {
  position: absolute;
  top: 2500px;
  left: -247px;

  width: 692px;
  height: 699px;

  opacity: 0.7;
  background: radial-gradient(55.35% 55.35% at 42.17% 44.65%, rgb(255 123 202 / 30%) 0%, rgb(255 123 202 / 0%) 82.35%);

  @media #{$phone} {
    display: none;
  }
}

.bubbleRightTopMobile {
  position: absolute;
  top: 114px;
  right: -282px;

  width: 498px;
  height: 520px;

  opacity: 0.7;
  background: radial-gradient(48.35% 48.16% at 46.6% 51.84%, rgb(255 123 202 / 40%) 0%, rgb(255 123 202 / 0%) 82.35%);

  @media #{$tablet-and-desktop} {
    display: none;
  }
}


.bubbleRightMiddleMobile {
  position: absolute;
  top: 1629px;
  right: -276px;

  width: 498px;
  height: 777px;

  background: radial-gradient(48.35% 48.16% at 46.6% 51.84%, rgb(255 123 202 / 40%) 0%, rgb(255 123 202 / 0%) 82.35%);

  @media #{$tablet-and-desktop} {
    display: none;
  }
}


.bubbleRightBottomMobile {
  position: absolute;
  top: 3700px;
  right: -362px;

  width: 671px;
  height: 768px;

  background: radial-gradient(50% 50% at 50% 50%, rgb(66 221 255 / 30%) 0%, rgb(17 112 255 / 0%) 100%);

  @media #{$tablet-and-desktop} {
    display: none;
  }
}

.bubbleLeftMobile {
  position: absolute;
  top: 3050px;
  left: -300px;

  width: 498px;
  height: 777px;

  background: radial-gradient(48.35% 48.16% at 46.6% 51.84%, rgb(255 123 202 / 40%) 0%, rgb(255 123 202 / 0%) 82.35%);

  @media #{$tablet-and-desktop} {
    display: none;
  }
}

