@import "../../include";

.container {
  @extend %content-container;

  display: flex;

  @media #{$tablet-and-desktop} {
    justify-content: space-between;
    min-height: 660px;
  }

  @media #{$phone} {
    flex-direction: column;
  }
}

.mainTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media #{$tablet-and-desktop} {
    min-width: 670px;
  }
}

.mainText {
  margin-top: 94px;

  font-size: 80px;
  font-weight: 600;
  line-height: 94px;
  color: #282828;

  i {
    font-style: normal;
    color: #4690FF;
  }

  @media #{$tablet-and-desktop} {
    align-self: flex-start;
  }

  @media #{$phone} {
    margin-top: 12px;
    font-size: 40px;
    line-height: 44px;
    text-align: center;
  }
}

.mainText + .mainTextButton {
  margin-top: 48px;

  @media #{$phone} {
    margin-top: 28px;
  }
}

.mainTextButton {
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  justify-content: center;

  min-width: 248px;
  min-height: 73px;
  padding: 4px;

  color: #FFF;
  text-decoration: none;

  background: #4690FF;
  border: 0;
  border-radius: 12px;

  transition: 125ms linear background;

  &:hover {
    background: #1573FF;
  }

  &:active {
    background: #1573FF;
  }

  span {
    color: #FFF;

    @media #{$tablet-and-desktop} {
      font-size: 22px;
      font-weight: 900;
      line-height: 30px;
    }

    @media #{$phone} {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }
  }

  span + svg {
    margin-left: 10px;
  }

  @media #{$phone} {
    align-self: unset;
    min-width: 225px;
  }
}

.mainTextContainer + .bannerImageContainer {
  @media #{$tablet-and-desktop} {
    margin-left: 32px;
  }

  @media #{$phone} {
    margin-top: 48px;
  }
}

$bannerImgWidth: 735px;

.bannerImageContainer {
  position: relative;
  z-index: 0;

  flex-grow: 1;

  aspect-ratio: 735/656;
  max-width: 735px;
}

.bannerImageManContainer {
  position: absolute;
  z-index: 2;
  top: 1%;
  left: 0.2%;

  aspect-ratio: 635/622;
  width: 86.25%;
  height: auto;

  img {
    position: absolute !important; // Special usecase for fixing overlapped images
    width: 100%;
  }
}

.bannerImageManBackground {
  position: absolute;
  z-index: 1;
  top: 15.0%;
  left: 20.0%;

  width: 73.46%;
  height: auto;
}

.bannerImageTextCanadianCompany {
  position: absolute;
  z-index: 3;
  top: 87.5%;
  left: 43.54%;

  width: 67.35%;
  height: auto;
}

.canadianCompanyTextContainer {
  position: absolute;
  z-index: 3;
  top: 87.5%;
  left: 54.5%;

  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 335px;
  height: 84px;

  background-color: white;
  border-radius: 10px;
  box-shadow: 0 100px 80px rgb(0 0 0 / 7%),
  0 41.7776px 33.4221px rgb(0 0 0 / 5.03%),
  0 22.3363px 17.869px rgb(0 0 0 / 4.17%),
  0 12.5216px 10.017px rgb(0 0 0 / 3.5%),
  0 6.6501px 5.32px rgb(0 0 0 / 2.83%),
  0 2.7672px 2.2138px rgb(0 0 0 / 1.97%);

  div {
    width: 64px;
    height: 64px;

    svg {
      width: 100%;
      height: 100%;
    }

    @media #{$phone} {
      width: 28px;
      height: 28px;
    }
  }

  span {
    font-size: 18px;
    font-weight: 500;
    color: #282828;

    @media #{$phone} {
      font-size: 8px;
    }
  }

  @media #{$phone} {
    width: 147px;
    height: 37px;
  }

  @media #{$phone} {
    border-radius: 5px;
  }
}
