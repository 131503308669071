@import "../../include";

.container {
  @extend %content-container;
}

.swiperContainer {
  @media #{$phone} {
    min-height: 470px;
    padding-bottom: 40px;

    :global(.swiper-pagination-bullets.swiper-pagination-horizontal) {
      display: flex;
      align-items: center;
      justify-content: center;

      :global(.swiper-pagination-bullet) {
        $d: 11px;

        width: $d;
        height: $d;
        margin: 0 9px;

        opacity: 1;
        background-color: #4690FF;
      }

      :global(.swiper-pagination-bullet.swiper-pagination-bullet-active) {
        box-shadow: 0 0 0 5px rgb(70 144 255 / 30%), 0 0 0 1px rgb(70 144 255 / 100%);
      }
    }
  }
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 50px;
    font-weight: 700;
    line-height: 68px;
    color: #282828;
    text-align: center;
    text-transform: capitalize;

    i {
      color: #4690FF;
    }

    @media #{$phone} {
      margin-left: -6px; // Designer hello
      font-size: 24px;
      line-height: 32px;
    }
  }
}

.title + .articlesList {
  @media #{$tablet-and-desktop} {
    margin-top: 60px;
  }

  @media #{$phone} {
    margin-top: 40px;
  }
}

.articlesList {
  display: flex;
}

.article {
  @media #{$tablet-and-desktop} {
    cursor: pointer;
  }
}

.articleImage {
  overflow: hidden;
  aspect-ratio: 467/234;
  border-radius: 12px;

  img {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: auto;

    object-fit: cover;
    object-position: center;
  }

  @media #{$phone} {
    aspect-ratio: 333/242;
  }
}

.articleImage + .articleTags {
  @media #{$tablet-and-desktop} {
    margin-top: 30px;
  }

  @media #{$phone} {
    margin-top: 22px;
  }
}

.articleTags {
  display: flex;
  flex-wrap: wrap;
}

.articleTag {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 38px;
  padding: 4px 18px;

  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  color: #FFF;

  background: #4690FF;
  border-radius: 38px;

  @media #{$phone} {
    height: 35px;
    padding: 2px 13px 4px;
    border-radius: 35px;
  }
}

.articleTags + .articleTitle {
  margin-top: 17px;

  @media #{$phone} {
    margin-top: 12px;
  }
}

.articleTitle {
  display: block;

  font-size: 30px;
  font-weight: 700;
  line-height: 41px;
  color: #282828;
  text-decoration: unset;
  text-transform: capitalize;

  @media #{$phone} {
    font-size: 20px;
    line-height: 27px;
  }
}

.articleTitle + .articleTitleAndDateDivider,
.articleTitleAndDateDivider + .articleDateAndAuthor {
  margin-top: 15px;

  @media #{$phone} {
    margin-top: 10px;
  }
}

.articleTitleAndDateDivider {
  border-top: 1px dashed #CCC;
}

.articleDateAndAuthor {
  display: flex;
  align-items: center;

  i {
    display: block;
    flex-shrink: 0;

    width: 8px;
    height: 8px;

    background: #4690FF;
    border-radius: 50%;

    @media #{$phone} {
      width: 6px;
      height: 6px;
    }
  }

  * + i,
  i + * {
    margin-left: 10px;

    @media #{$phone} {
      margin-left: 12px;
    }
  }
}

.articleDate {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #282828;
}

.articleAuthor {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #282828;
}
